.navbar-expand-lg .navbar-collapse{
  justify-content: flex-end!important;
}

.bg-light {
  background-color: #2c3e50!important;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  color: rgb(255 255 255 / 87%);
}
.navbar-light .navbar-nav .nav-link {
  color: rgb(255 255 255);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgb(255 255 255 / 70%);
  /* font-weight: 700; */
  /* border-bottom: 2px solid white; */
}
.navbar-light .navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-light .navbar-toggler {
 
  border-color: rgb(254 254 254 / 37%);
}

.mr-auto.navbar-nav {
  margin-right: 44px;
}