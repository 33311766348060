.usluge {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin-bottom: 30px;
}
.usluge a {
  border: 1px solid #0000001f;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 2px 2px #2c3e50cf;
  color: black;
  text-decoration: none;
  font-size: 1.3rem;
  transition: 1s all ease;
  padding: 5px;
}

.usluge a:hover {
  background: #d9d9d9;
}

@media (max-width:767px){
  .usluge {
   
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 450px){

.usluge {
    grid-template-columns: 1fr;
}
}