.footer {
  background: black;
  color: white;
  padding: 60px 0 60px 0;
}

a.whasapp {
  position: fixed;
  z-index: 9999999999;
  bottom: 1px;
  left: 6px;
}